import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7bffa49a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "w-100 d-flex justify-content-center",
  style: {"padding-top":"60px"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "text-center header"
}
const _hoisted_4 = { class: "error-list" }
const _hoisted_5 = { class: "error-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_modal = _resolveComponent("el-modal")!

  return (_openBlock(), _createBlock(_component_el_modal, {
    btnLabel: _ctx.$t('dashboard-start-spring'),
    "modal-wrapper-class": " code-inputs",
    "modal-size": "lg"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          style: {"height":"125px"},
          src: require('@/assets/images/retry.png'),
          alt: ""
        }, null, 8, _hoisted_2)
      ])
    ]),
    body: _withCtx(() => [
      (!_ctx.title)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(_ctx.$t("validation-error")), 1))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.errors, (error) => {
          return (_openBlock(), _createElementBlock("li", _hoisted_5, _toDisplayString(error), 1))
        }), 256))
      ])
    ]),
    _: 1
  }, 8, ["btnLabel"]))
}