<template>
  <el-modal
    btnClass="bt-golden"
    btnIcon="face-smile"
    :btnLabel="$t('dashboard-start-spring')"
    modal-wrapper-class="my-campaign-modal "
    @primaryClick="startSpring"
    :modal-size="isMobile ? 'xs' : 'lg'"
    :disabled="!kvkkCheckbox"
  >
    <template v-slot:header>
      <img class="w-100" :src="require('@/assets/images/modal.png')" alt="" />
    </template>
    <template v-slot:body>
      <div class="header">
        <span class="nav-text warning bold"
          >TheSpring {{ calculateWindowWidth }}</span
        >
        <h4 class="text-center second-font">
          {{ $t("the-spring-title") }}
        </h4>
      </div>
      <p class="gray text-center mb-0">
        Mollis massa in sit cursus a sed. Eu eget viverra ut quis eget fringilla
        in. Quam mauris in tellus viverra hac diam vel, tellus tortor.
      </p>
      <checkbox v-model="kvkkCheckbox" id="kvkk">
        <template v-slot>
          <a href="/kvkk" class="primary" @click="goDetailPage">KVKK</a>
          {{ $t("dashboard-kvkk") }}
        </template>
      </checkbox>
      <!--      {{ this.v$.kvkkCheckbox }}-->
    </template>
  </el-modal>
</template>

<script>
import { computed, ref } from "vue";
import ElModal from "@/components/UI/Modal.vue";
import Checkbox from "@/components/UI/Checkbox";
import { useRouter } from "vue-router";
import { modalStore } from "@/store/modules/modals";
export default {
  components: {
    ElModal,
    Checkbox,
  },
  setup() {
    const store = modalStore;
    const router = useRouter();
    const kvkkCheckbox = ref(false)
    const startSpring = () => {
      if (kvkkCheckbox.value === true) {
        sessionStorage.setItem('campaign-kvkk-accept', true)
        store.commit("closeModal");
        router.push("/dashboard/new-campaigns");
      } else {
        alert("qwe");
      }
    };
    const isMobile = computed(() => {
      if (window.innerWidth < 774) {
        return true;
      } else {
        return false;
      }
    });
    return { startSpring, isMobile, kvkkCheckbox };
  },
};
</script>

<style lang="scss" scoped>
.primary {
  user-select: none !important;
}
</style>
